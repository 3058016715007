<template>
	<section>
		<div class="container-lg py-4">
			<div class="row">
				<div class="col-12 text-center">
					<h1 class="text-bold gold" v-html="$t('ABOUT US')"></h1>
				</div>
			</div>
			<div class="row my-5 py-4">
				<u-animate-container class="col-md-12 text-center">
					<u-animate name="fadeInUp">
						<img src="@/assets/images/img-about1.jpg" class="ir" />
					</u-animate>
				</u-animate-container>
				<a id="vm"></a>
				<div class="col-md-6 text-right mt-5">
					<div class="vm vision">
						<div class="content">
							<h2 class="blue vision"><b v-html="$t('VISION')"></b></h2>
							<p v-html="$t('Greatest leader in Southeast Asia for Healthcare Innovative platform and health data centralization for health business transformation')"></p>
						</div>
					</div>
				</div>

				<div class="col-md-6 text-left">
					<div class="vm mission">
						<div class="content">
							<h2 class="blue mission"><b v-html="$t('MISSION')"></b></h2>
							<p v-html="$t('mission_detail')"></p>
						</div>
					</div>
				</div>
				<div class="col-12 pb-0 pb-md-5"></div>
			</div>
		</div>

		<a id="management"></a>
		<div class="container-fluid bg-gray cng">
			<div class="container-lg py-4">
				<div class="row">
					<div class="col-12 text-center">
						<h2 class="blue"><b v-html="$t('Management Team')"></b></h2>
					</div>
					<div class="col-12 text-center">
						<p v-html="$t('Meet our expers of healthcare innovative industry')"></p>
					</div>
				</div>
			</div>
			<div class="container-lg cng">
				<div class="row no-gutters">
					<div class="col-12 d-flex flex-wrap flex-lg-nowrap justify-content-between">
						<div class="profile-box flex-fill">
							<div class="image"><img src="@/assets/images/team1.jpg" class="ir" /></div>
							<div class="title" v-html="$t('ceo')"></div>
						</div>
						<div class="profile-box flex-fill">
							<div class="image"><img src="@/assets/images/team2.jpg" class="ir" /></div>
							<div class="title" v-html="$t('coo')"></div>
						</div>
						<div class="profile-box flex-fill">
							<div class="image"><img src="@/assets/images/team3.jpg" class="ir" /></div>
							<div class="title" v-html="$t('cpo')"></div>
						</div>
						<div class="profile-box flex-fill">
							<div class="image"><img src="@/assets/images/team4.jpg" class="ir" /></div>
							<div class="title" v-html="$t('cto')"></div>
						</div>
						<div class="profile-box flex-fill">
							<div class="image"><img src="@/assets/images/team5.jpg" class="ir" /></div>
							<div class="title" v-html="$t('cfo')"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<a id="docnet"></a>
		<div class="container-fluid cng mt-5 py-5">
			<div class="container-fluid my-5 bg-gray">
				<div class="container">
					<div class="row">
						<div class="col-md-6 text-right">
							<div class="docnet">
								<h3><b v-html="$t('Our Doctor’s Networks')"></b></h3>
								<p v-html="$t('From our expert experience of more than 20 years in the healthcare industry, we connect the specialist doctor community for people who need medical assistance during their suffering illness period via an easier channel. With 19 current specialties, we aim to expand this community to be the biggest expert community for people')"></p>
							</div>
						</div>
						<u-animate-container class="col-md-6">
							<u-animate class="img-docnet" name="fadeInUp"><img src="@/assets/images/img-about2.jpg" class="ir" /></u-animate>
						</u-animate-container>
					</div>
				</div>
			</div>
		</div>

		<a id="awards"></a>
		<div class="container-fluid pb-5">
			<div class="container">
				<div class="row">
					<div class="col-12 text-center">
						<h2><b v-html="$t('Our Awards')"></b></h2>
					</div>
				</div>
				<div class="row">
					<div class="col-12 my-4">
						<VueSlickCarousel :autoplay="true" :speed="1500" :arrows="false" :pauseOnHover="false" :slidesPerRow="4" :rows="2" class="logos-slide">
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner1.png" class="ir" /></div>
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner2.png" class="ir" /></div>
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner3.png" class="ir" /></div>
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner4.png" class="ir" /></div>
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner5.png" class="ir" /></div>
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner6.png" class="ir" /></div>
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner7.png" class="ir" /></div>
							<div class="logos-item gray my-4"><img src="@/assets/images/logo-partner8.png" class="ir" /></div>
						</VueSlickCarousel>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-center mt-4">
						<a id="partnership"></a>
						<h2><b v-html="$t('Our Partnership')"></b></h2>
					</div>
					<div class="col-12 my-4">
						<VueSlickCarousel :autoplay="true" :speed="1500" :arrows="false" :pauseOnHover="false" :slidesPerRow="4" :rows="2" class="partner-slide">
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership1.png" class="ir" /></div>
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership2.png" class="ir" /></div>
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership3.png" class="ir" /></div>
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership4.png" class="ir" /></div>
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership5.png" class="ir h85px" /></div>
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership6.jpg" class="ir h85px" /></div>
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership7.png" class="ir h85px" /></div>
							<div class="logos-item my-4"><img src="@/assets/images/logo-partnership8.jpg" class="ir h85px" /></div>
						</VueSlickCarousel>
					</div>
				</div>

			</div>
		</div>

		<a id="form"></a>
		<div class="container-fluid bg-form5">
			<div class="container py-0 py-md-5">
				<div class="row">
					<div class="col-lg-8 offset-lg-2 py-5">
						<form class="apply" @submit.prevent="sendResume">
							<div class="row">
								<div class="col-12 text-center">
									<h2 class="pb-0"><b v-html="$t('Join Our Team')"></b></h2>
									<h1 class="text-bold pt-0" v-html="$t('APPLY HERE')"></h1>
								</div>
								<div class="col-12 text-center">
									<p v-html="$t('Complete the form below, and we will contact you to schedule an interview within 1-2 business days')"></p>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Name')"></label>
									<input type="text" :placeholder="$t('Name')" required v-model="model.name" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Surname')"></label>
									<input type="text" :placeholder="$t('Surname')" required v-model="model.surename" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Phone')"></label>
									<input type="tel" :placeholder="$t('Phone')" required maxlength="10" v-model="model.phone" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('E-mail')"></label>
									<input type="email" :placeholder="$t('Email')" required v-model="model.email" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Position')"></label>
									<input type="text" :placeholder="$t('Position')" required v-model="model.position" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Resume')"></label>
									<input type="file" accept="application/pdf" />
								</div>
								<div class="col-12 text-center mt-3">
									<input type="submit" class="btn-submit" :value="$t('SEND')" />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="container py-5"></div>

		<a id="contact"></a>
		<div class="container-fluid bg-gray">
			<div class="container-lg">
				<div class="row">
					<div class="col-md-4 offset-md-2 px-3 py-3 address">
						<h1><b v-html="$t('Contact Us')"></b></h1>
						<p v-html="$t('daz_address_line_1')"></p>
						<p v-html="$t('daz_address_line_2')"></p>
						<br />
						<p><b>ไม่พลาดทุกข่าวสาร ทันทุกเหตุการณ์<br />Official Account : Doctor A to Z</b></p>
						<p><img src="@/assets/images/line.png" /></p>
					</div>
					<div class="col-md-6">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.5449570138408!2d100.57265247509127!3d13.806282886591301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d659eb9785c8f%3A0xd1cd8c1aa58a06ba!2sDoctor%20A%20to%20Z%20Co.%2CLTD.!5e0!3m2!1sth!2sth!4v1717665972371!5m2!1sth!2sth" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
	components: {
		VueSlickCarousel
	},
	data: () => ({
		model: {
			name: '',
			surename: '',
			phone: '',
			email: '',
			position: '',
			resume: ''
		},
		modelEmail: {
			type: 'admin',
			subject: 'Join Our Team with Doctor A to Z',
			from: '',
			to: process.env.VUE_APP_EMAIL_SERVICE,
			html: ''
		}
	}),
	methods: {
		async sendResume() {
			let html = ''
			html += '<div>Name: ' + this.model.name + ' ' + this.model.surename + '</div><br/>';
			html += '<div>Email: ' + this.model.email + ' </div><br/>';
			html += '<div>Phone: ' + this.model.phone + ' </div><br/>';
			html += '<div>Position ' + this.model.position + ' </div><br/><br/>';
			html += '<div>Sincerly yours,</div><br/>';
			html += '<div>Doctor A to Z</div>';

			this.modelEmail.html = html;
			this.modelEmail.from = this.model.email;

			let urlEmail = "/email";
			await window.axios.post(urlEmail, this.modelEmail).then(async () => {
				alert(this.$t('Profile has been sent successfully'));
				this.model = {
					name: '',
					surename: '',
					phone: '',
					email: '',
					position: '',
					resume: ''
				}
			}).catch(error => {
				console.log(urlEmail, error)
			});
		}
	}
}
</script>
